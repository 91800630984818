import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';

import InfoBanner from 'components/InfoBanner';
import TopBanner from 'components/TopBanner';
import DiscoveryTextSection from 'components/DiscoveryTextSection';
import SubMenu from 'components/common/SubMenu';
import RecommendedProducts from 'components/RecommendedProducts';
import BreadCrumbs from 'components/common/BreadCrumbs';
import './DiscoverPage.scss';

import { TDiscoveryPageComponentProps } from './models';

const DiscoveryPage: FC<TDiscoveryPageComponentProps> = ({
  data: {
    discovery: {
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      openGraphImageUrl,
      bottomBanner,
      topBanner: [{ properties: topBannerData }],
      sections,
      subMenu,
      anchorMenu: [{ properties: anchorMenuSettings }],
      recommendedProducts,
      showBreadcrumbs,
    },
    siteSettings,
    commonSettings,
    header,
    footer,
    filteredRecommendedProducts,
  },
  pageContext: { recommendedProductsLinks, breadCrumbs },
}) => {
  const recommendedProductsProps = {
    productLinks: recommendedProductsLinks?.map((url) => ({
      properties: { link: [{ url }] },
    })),
    products: filteredRecommendedProducts,
    title: recommendedProducts?.properties.title,
    isFamily: recommendedProducts?.properties.isFamily,
    isArticlePage: false,
  };

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        openGraphImageUrl,
      }}
      siteSettings={siteSettings}
      commonSettings={commonSettings}
      header={header}
      footer={footer}
    >
      <BreadCrumbs data={breadCrumbs} showBreadcrumbs={showBreadcrumbs} />
      <TopBanner {...topBannerData} classes="discover-top-banner" />
      <SubMenu links={subMenu} settings={anchorMenuSettings} />
      {sections.map(({ properties }) => (
        <DiscoveryTextSection key={properties.title} {...properties} />
      ))}
      {bottomBanner.length ? <InfoBanner {...bottomBanner[0].properties} /> : null}
      {recommendedProductsProps.productLinks.length ? (
        <div className="container-fluid discover-recommended-products">
          <RecommendedProducts {...recommendedProductsProps} />
        </div>
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query DiscoveryPageQuery($recommendedProductsLinks: [String], $link: String, $lang: String) {
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    commonSettings(lang: { eq: $lang }) {
      blocks {
        structure
        properties {
          ...FragmentDefaultCommonSettingsProps
        }
      }
    }
    header(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footer(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    filteredRecommendedProducts: allProduct(filter: { link: { in: $recommendedProductsLinks } }) {
      nodes {
        link
        productImage {
          ...FragmentGatsbyProps
          gatsbyImage {
            ...FragmentSmallImage
          }
          mobileImage {
            childImageSharp {
              fluid(maxHeight: 210) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        imageFamily {
          ...FragmentGatsbyProps
          gatsbyImage {
            ...FragmentSmallImage
          }
          mobileImage {
            childImageSharp {
              fluid(maxHeight: 210) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        productImageAlt
        cardTitle
        familyCardTitle
        tickItems {
          value
        }
        tags {
          isProductFamily
          color {
            label
          }
        }
        featureItems {
          properties {
            icon
            label
          }
        }
        cartFakeLink
        currentFormat {
          properties {
            label
          }
        }
      }
    }
    discovery(link: { eq: $link }, lang: { eq: $lang }) {
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        key
        value
      }
      openGraphImageUrl
      id
      link
      title
      showBreadcrumbs
      recommendedProducts {
        properties {
          isFamily
          title
        }
      }
      subMenu {
        linkToSection
        name
      }
      seoMetaTitle
      seoMetaDescription
      topBanner {
        ...FragmentTopBanner
      }
      sections {
        properties {
          blocks {
            properties {
              description
              image {
                ...FragmentGatsbyImage
              }
              imageAlt
              title
            }
          }
          color
          description
          image {
            ...FragmentGatsbyImage
          }
          imageAlt
          showInMenu
          title
          subtitle
          anchorName
          history {
            properties {
              description
              image {
                ...FragmentGatsbyImage
              }
              imageAlt
              year
              ariaDot
            }
          }
          imagePosition
        }
      }
      bottomBanner {
        ...FragmentBottomBanner
      }
      anchorMenu {
        properties {
          mobileTitle
          ariaAnchorMenu
        }
      }
    }
  }
`;

export default DiscoveryPage;
